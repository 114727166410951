import React from "react";
import { toast } from "react-toastify";

class ErrorBoundaryClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      isAuthError: false,
    };
  }

  componentDidMount() {
    window.addEventListener("parseError", this.handleParseError);
  }

  componentWillUnmount() {
    window.removeEventListener("parseError", this.handleParseError);
  }

  handleParseError = (event) => {
    const { code, message } = event.detail;

    if (code === 209 || code === 401) {
      toast.error("Session expired. Please login again.", {
        autoClose: 2000,
        onClose: () => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/login";
        },
      });
    } else {
      // Handle other errors
      toast.error(message || "An unexpected error occurred");
      this.setState({
        hasError: true,
        isAuthError: false,
        error: {
          message: message || "An unexpected error occurred",
        },
      });
    }
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
      isAuthError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorUI error={this.state.error} />;
    }

    return this.props.children;
  }
}

const ErrorUI = ({ error }) => {
  const handleLoginClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center p-8 bg-white rounded-lg shadow-md max-w-md">
        <h1 className="text-2xl font-bold text-red-600 mb-4">
          Oops! Something went wrong
        </h1>
        <p className="text-gray-600 mb-6">
          {error?.message || "Please try refreshing the page or login again"}
        </p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Refresh Page
          </button>
          <button
            onClick={handleLoginClick}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryClass;
