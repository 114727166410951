import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { ApolloLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";

import enviornment from "./env";
import store from "./store";

console.log("Initializing Apollo Client");

const uploadLink = createUploadLink({
  uri: enviornment.graphqlURL,
});

const authLink = setContext((_, { headers }) => {
  let { sessionToken } = store.getState().authSlice;
  if (!sessionToken) sessionToken = "";
  const authHeaders = { "X-Parse-Application-Id": "inspacco-parse-server" };
  if (sessionToken) {
    authHeaders["X-Parse-Session-Token"] = sessionToken;
  }

  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

// Network error handler
const errorLink = onError(
  ({ networkError, graphQLErrors, operation, forward }) => {
    console.log("Error Link - Checking errors", {
      networkError,
      graphQLErrors,
    });

    if (networkError) {
      console.log("Network error details:", networkError);

      // For Parse Server errors
      if (networkError.result) {
        // Parse Server usually sends errors in result
        const errorResult = networkError.result;
        if (errorResult.code === 209 || errorResult.code === 401) {
          window.dispatchEvent(
            new CustomEvent("parseError", {
              detail: {
                code: errorResult.code,
                message: errorResult.error || "Invalid session token",
              },
            })
          );
          return;
        }
      }

      // Handle other network errors
      window.dispatchEvent(
        new CustomEvent("parseError", {
          detail: {
            code:
              networkError.statusCode || networkError.status || "NETWORK_ERROR",
            message: networkError.message || "Network error occurred",
          },
        })
      );
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        console.log("GraphQL Error:", error);
        if (
          error.extensions?.code === 209 ||
          error.extensions?.code === 401 ||
          error.message?.includes("Invalid session token")
        ) {
          window.dispatchEvent(
            new CustomEvent("parseError", {
              detail: {
                code: error.extensions?.code,
                message: error.message || "Invalid session token",
              },
            })
          );
        }
      });
    }
  }
);

// Response interceptor for successful responses
const responseInterceptorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    console.log("Intercepting response:", response);

    // Check for Parse Server error in response
    if (response.data?.code === 209 || response.data?.error?.code === 401) {
      console.log("Found Parse Server auth error in response");
      window.dispatchEvent(
        new CustomEvent("parseError", {
          detail: {
            code: response.data?.error?.code,
            message: response.data?.error || "Invalid session token",
          },
        })
      );
    }
    return response;
  });
});

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      console.log("Retry Link - Checking if should retry:", error);
      // Don't retry auth errors
      if (error?.extensions?.code === 209 || error?.extensions?.code === 401)
        return false;
      // Don't retry 400 Bad Request errors
      if (error?.statusCode === 400 || error?.status === 400) return false;
      // Don't retry Parse Server errors
      if (error?.result?.code === 209) return false;
      // retry if the server returns a 5xx error
      return !!error && error.status >= 500 && error.status < 600;
    },
  },
});

// Combine all links using from
const client = new ApolloClient({
  link: from([
    errorLink, // Handle network errors first
    responseInterceptorLink, // Then check successful responses
    retryLink,
    authLink,
    uploadLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

console.log("Apollo Client initialized");

export default client;
